import './public-path';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import './index.scss';
import { Provider } from 'react-redux';
import store from './store';
import { message } from 'antd';

message.config({ top: 85 });

function getRootContainer(container) {
  return container ? container.querySelector('#expense-split-web') : document.querySelector('#expense-split-web');
}

function render(props) {
  const { container } = props;
  const rootContainer = createRoot(getRootContainer(container));

  rootContainer.render(
    <Provider store={store}>
      <App store={{ ...props }} />
    </Provider>
  );
}

if (!window.__POWERED_BY_QIANKUN__) {
  render({});
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export async function bootstrap() {
}

export async function mount(props) {
  render(props);
}

export async function unmount(props) {
  const { container } = props;
  ReactDOM.unmountComponentAtNode(getRootContainer(container));
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
