import { configureStore, createSlice } from '@reduxjs/toolkit';

const loadingReducer = createSlice({
  name: 'loading',
  initialState: { spinning: false },
  reducers: {
    setLoading: (state, action) => {
      state.spinning = action.payload;
    }
  }
});


const store = configureStore({
  reducer: {
    loading: loadingReducer.reducer,
  },
  devTools: true
});

export const setLoading = loadingReducer.actions.setLoading;

export const selectLoading = state => state.loading.spinning;

export default store;
