import { get, isString } from 'lodash-es';

export const TOKEN_INFO_KEY = 'okta-token-storage';
export const INFO_KEY = 'expense-split-web';

export const localStorageUtils = {
  getItem: (key: string, parseValue = false) => {
    const value = localStorage.getItem(key);
    if (parseValue) {
      try {
        return JSON.parse(value);
      } catch (e) {
        return value;
      }
    } else {
      return value;
    }
  },
  setItem: (key: string, value: string | object) => {
    const localValue = isString(value) ? value : JSON.stringify(value);
    localStorage.setItem(key, localValue);
  },
  setCacheItem: (key: string, value: string | object) => {
    const cacheObj = localStorageUtils.getItem(INFO_KEY, true) || {};
    cacheObj[key] = value;
    let cacheStr;
    try {
      cacheStr = JSON.stringify(cacheObj);
    } catch {
      return;
    }
    try {
      localStorage.setItem(INFO_KEY, cacheStr);
    } catch { // QuotaExceededError
      localStorage.removeItem(INFO_KEY);
    }
  },
  getCacheItem: (key: string) => {
    const cacheObj = localStorageUtils.getItem(INFO_KEY, true) || {};
    return cacheObj[key];
  },
  removeCacheItem: (key: string) => {
    const cacheObj = localStorageUtils.getItem(INFO_KEY, true) || {};
    delete cacheObj[key];
    localStorage.setItem(INFO_KEY, JSON.stringify(cacheObj));
  },
  removeItem: (key: string) => {
    localStorage.removeItem(key);
  },
  clear: () => {
    localStorage.clear();
  }
};

export const getToken = () => {
  return  get(localStorageUtils.getItem(TOKEN_INFO_KEY, true), 'accessToken.accessToken', '');
};
