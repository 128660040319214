import RootElement from '@/pages/root-element/RootElement';
import { RouterObjectExtend } from '@/models/common';
import { Navigate } from 'react-router-dom';

export const routerConfig: RouterObjectExtend[] =  ([
  {
    path: '/',
    element: <RootElement />,
    children: [
    ]
  },
  {
    path: '*',
    element: <Navigate to='/'/>
  }
] as RouterObjectExtend[]);
