import { IBillSplitInfo } from '@/models/billSplit';
import { DATE_FORMATTER } from '@/constants/date';
const moment = require('moment');

export const transformDateMomentToDateStr = fieldsValue => {
  const billSplitInfo: IBillSplitInfo = {
    expenseSheetUrl: null,
    uInfoSheetUrl: null,
    expenseSplitStartDate: null,
    expenseSplitEndDate: null
  };
  Object.keys(fieldsValue).forEach(key => {
    const value = fieldsValue[key];
    if (key === 'expenseSplitPeriod') {
      billSplitInfo.expenseSplitStartDate = value.map(item => item.format(DATE_FORMATTER))[0];
      billSplitInfo.expenseSplitEndDate = value.map(item => item.format(DATE_FORMATTER))[1];
    } else {
      billSplitInfo[key] = value;
    }
  });
  return billSplitInfo;
};

export const transformDateStrToDateMoment = cacheInfo => {
  const formFieldsValue = { expenseSplitPeriod: [] };
  Object.keys(cacheInfo).forEach(key => {
    const value = cacheInfo[key];
    if (key === 'expenseSplitStartDate') {
      formFieldsValue.expenseSplitPeriod[0] = moment(value, DATE_FORMATTER);
    } else if (key === 'expenseSplitEndDate') {
      formFieldsValue.expenseSplitPeriod[1] = moment(value, DATE_FORMATTER);
    } else {
      formFieldsValue[key] = value;
    }
  });
  return formFieldsValue;
};