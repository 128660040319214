// eslint-disable-next-line no-use-before-define
import React, { useCallback, useEffect, useState } from 'react';
import '@/pages/root-element/RootElement.scss';
import { Alert, Button, DatePicker, Form, Input, Modal } from 'antd';
import Icon from '@ant-design/icons';
import AlertSvg from '@/assets/icons/alert.svg';
import SpinSvg from '@/assets/icons/spin.svg';
import { getToken, localStorageUtils } from '@/utils/localStorageUtils';
import { splitBill } from '@/api/billSplitApi';
import {
  CONTACT_PEOPLE_EMAIL_NOT_EXIST,
  DRIVER_FOLDER_NOT_EXIST,
  REQUIRED_SHEET_COLUMNS_MISSING,
  REQUIRED_SHEET_MISSING,
  YEAR_DRIVER_FOLDER_OR_BILL_SPLIT_DRIVER_FOLDER_NOT_EXIST,
  U_NAME_MISSING
} from '@/constants/error';
import { transformDateMomentToDateStr, transformDateStrToDateMoment } from '@/pages/root-element/RootElementUtils';
import NoPermission from '@/components/no-permission/NoPermission';

const BILL_SPLIT_CACHE_KEY = 'billSplit';

const RootElement = () => {
  const [ form ] = Form.useForm();
  const [ btnDisable, setBtnDisable ] = useState(true);
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const [ isAlertOpen, setIsAlertOpen ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ errorMessageContent, setErrorMessageContent ] = useState([]);

  useEffect(() => {
    const cacheInfo = localStorageUtils.getCacheItem(BILL_SPLIT_CACHE_KEY);
    if (cacheInfo) {
      const formFieldsValue = transformDateStrToDateMoment(cacheInfo);
      form.setFieldsValue(formFieldsValue);
      setBtnDisable(false);
    }
  }, []);

  const onValuesChange = useCallback(() => {
    form.validateFields().catch(() => setBtnDisable(form.getFieldsError().some(item => item.errors.length > 0)));
  },[ form ]);

  const handleClick = () => {
    setLoading(true);
    setBtnDisable(true);
    const fieldsValue = form.getFieldsValue();
    const billSplitInfo = transformDateMomentToDateStr(fieldsValue);
    splitBill(billSplitInfo).then(res => {
      if ('errorMessage' in res) {
        setIsAlertOpen(true);
        setLoading(false);
        setErrorMessageContent(res.errorMessage);
        form.validateFields().then(() => setBtnDisable(false));
        localStorageUtils.setCacheItem(BILL_SPLIT_CACHE_KEY, billSplitInfo);
      }
      if ('data' in res) {
        setIsAlertOpen(false);
        setIsModalOpen(true);
        setLoading(false);
        localStorageUtils.removeCacheItem(BILL_SPLIT_CACHE_KEY);
      }
    }).catch(() => {
      setLoading(false);
      setIsAlertOpen(false);
    });
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getValidLink = fieldValue => {
    let index = fieldValue.indexOf('/');
    for (let i = 0; i < 5; i++) {
      index = fieldValue.indexOf('/', index + 1);
    }
    return fieldValue.substring(0, index);
  };

  const renderExpenseSheetUrlError = (content: string[]) => {
    if (content.includes(REQUIRED_SHEET_MISSING) ||
        content.find(item => item.includes(REQUIRED_SHEET_COLUMNS_MISSING)) !== undefined) {
      return <>
        {content.filter(message => message.includes(REQUIRED_SHEET_MISSING) || message.includes(REQUIRED_SHEET_COLUMNS_MISSING))
          .map(message => <p key={message}>{message}</p>)}
        请跳转此链接更改：
        <a
          href={getValidLink(form.getFieldValue('expenseSheetUrl'))}
          target="_blank"
          rel="noreferrer"
        >
          {getValidLink(form.getFieldValue('expenseSheetUrl'))}
        </a>
      </>;
    }
  };

  const renderUInfoSheetUrlError = (content: string[]) => {
    const filteredItems = content.filter(
      message => message.includes(DRIVER_FOLDER_NOT_EXIST) ||
      message.includes(CONTACT_PEOPLE_EMAIL_NOT_EXIST) ||
      message.includes(YEAR_DRIVER_FOLDER_OR_BILL_SPLIT_DRIVER_FOLDER_NOT_EXIST) ||
      message.includes(U_NAME_MISSING)
    );
    if (filteredItems.length > 0) {
      return <>
        {filteredItems.map(message => <p className="u-info-sheet-url-error" key={message}>{message}</p>)}
        {content.find(item => item.includes(CONTACT_PEOPLE_EMAIL_NOT_EXIST)) &&
          <div className="modify-link-wrapper">
        请跳转此链接更改：
            <a
              href={getValidLink(form.getFieldValue('uInfoSheetUrl'))}
              target="_blank"
              rel="noreferrer"
            >
              {getValidLink(form.getFieldValue('uInfoSheetUrl'))}
            </a>
          </div>
        }
      </>;
    }
  };

  const token = getToken();
  if (token) {
    return (
      <>
        <Form
          name="billSplit"
          className="bill-split"
          labelAlign="left"
          autoComplete="off"
          form={form}
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 21 }}
          onValuesChange={onValuesChange}
          requiredMark={false}
          validateTrigger={[ 'onBlur', 'onChange' ]}
        >
          <Form.Item
            label="账单链接"
            name="expenseSheetUrl"
            colon={false}
            rules={[ { required: true } ]}
          >
            <Input size="large" placeholder="请在此处粘贴账单 Spreadsheet 链接"/>
          </Form.Item>

          <Form.Item
            label="归属人/对接人"
            name="uInfoSheetUrl"
            colon={false}
            rules={[ { required: true } ]}
          >
            <Input size="large" placeholder="请在此处粘贴归属人/对接人 Spreadsheet 链接"/>
          </Form.Item>
          {
            isAlertOpen &&
                <Alert
                  message= {
                    <div>
                      {renderExpenseSheetUrlError(errorMessageContent)}
                      {renderExpenseSheetUrlError(errorMessageContent) !== undefined &&
                            renderUInfoSheetUrlError(errorMessageContent) !== undefined &&
                            <div className="split-line"></div>}
                      {renderUInfoSheetUrlError(errorMessageContent)}
                    </div>
                  }
                  type="error"
                  icon={<Icon className="archive-icon" component={AlertSvg}/>}
                  showIcon
                />
          }
          <Form.Item
            label="时间"
            name="expenseSplitPeriod"
            colon={false}
            rules={[ { type: 'array' as const, required: true } ]}
          >
            <DatePicker.RangePicker
              data-testid="expense-split-period"
              size="large"
              getPopupContainer={triggerNode => triggerNode}
            />
          </Form.Item>

          <Form.Item className="bill-split-btn">
            <Button type="primary" disabled={btnDisable} onClick={handleClick}>
              {loading ? '拆分中' : '账单拆分'}
              {loading && <Icon type="right" spin={true} component={SpinSvg}/>}
            </Button>
          </Form.Item>
        </Form>
        <Modal
          data-testid="bill-split-completion-modal"
          centered
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={600}
          footer={
            <Button type="primary" size="small" onClick={handleOk}>
                  确定
            </Button>
          }
        >
          <p>账单拆分完成，已经同步上传至 Google Drive 存档，信息同步至邮箱。</p>
        </Modal>
      </>
    );
  }
  return <NoPermission/>;
};

export default RootElement;
